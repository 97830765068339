@import "../../../assets/css/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .label {
    font-size: 17px;
    font-weight: 600;
    color: $color-black-font;
    margin-bottom: 28px;
  }

  .group-container {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .checkbox-container {
      display: flex;
      align-items: center;
    }

    .checkbox {
      width: 17px;
      height: 17px;
      border-radius: 2px;
      border: 0.7px solid #7791a2;
      display: flex;
      justify-content: center;
      align-items: center;

      .selected {
        width: 70%;
        height: 70%;
        background-color: #7791a2;
        border-radius: 1.3px
      }
    }

    .checkbox-label {
      margin-left: 5px;
      font-size: 15px;
      color: $color-black-font;
    }
  }
}

.mobile {
  .container {
    width: 300px;

    .group-container {
      width: 270px;
      padding-left: 15px;
      height: 80px;
      justify-content: space-between;
      .checkbox-container {
        display: inline-flex;
      }
    }
  }
}
