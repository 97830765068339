@import "../../assets/css/colors.scss";

.login-page {
  background-color: white;
  height: 100%;
  width: 100%;
}

.login-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.logo-container {
  width: 179px;
  height: 57px;
  object-fit: contain;
  margin: auto;

  &.desktop {
    width: 232px;
  }

  .logo {
    width: inherit;
  }
}

.login-user {
  background-color: white;
  border-radius: 5px;
  margin: 40px auto;
  padding: 10px 75px;
  width: 250px;

  &.desktop {
    box-shadow: 0 10px 24px 0 $color-grey-shadow-dark;
  }

  form {
    margin: 50px 0;
  }

  .form-group {
    margin-bottom: 40px;
    position: relative;

    .input-group {
      display: flex;
      flex-wrap: nowrap;
    }

    &.inactive {
      border-bottom: solid 1px $color-grey-light;
      .user-icon {
        background-image: url('../../assets/images/user-inactive.svg');
      }
      .password-icon {
        background-image: url('../../assets/images/pass-inactive.svg');
      }
    }

    &.active {
      border-bottom: solid 1px $color-grey-blue-middle;
      .user-icon {
        background-image: url('../../assets/images/user-active.svg');
      }
      .password-icon {
        background-image: url('../../assets/images/pass-active.svg');
      }
    }

    .input-error-message {
      display: none;
    }

    &.error {
      border-bottom-color: $color-red;

      .input-error-message {
        bottom: -22px;
        color: $color-red;
        display: block;
        font-size: 14px;
        font-weight: 600;
        position: absolute;
      }
    }

    input {
      border: none;
      box-shadow: none;
      color: $color-grey-blue-dark;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: normal;
      padding-left: 20px;

      &::placeholder {
        color: $color-grey-blue-light;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .input-icon {
      background-color: transparent;
      border: none;
      padding: 6px 0px;
      width: 25px;

      div {
        background-repeat: no-repeat;
        height: 17px;
        margin: 8px auto;
        width: 17px;
      }
    }
  }

  button.btn {
    background-color: $color-grey-blue-middle;
    border: none;
    border-radius: 9px;
    color: white;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding: 13px 16px;
    width: 100%;
    cursor: pointer;

    &:disabled {
      background-color: $color-grey-light;
      color: $color-grey-dark;
      cursor: not-allowed;
    }

    &:focus {
      box-shadow: none;
      background-color: $color-grey-blue-middle;
      color: white;
      outline: none;
    }
  }

  .error-message {
    color: $color-red;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin: 15px 0;
    text-align: center;
  }
}


.mobile {
  .login-user {
    padding: 0;
  }
}
