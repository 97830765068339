@import "../../../assets/css/colors.scss";

.datepicker-container {
  input {
    width: 90px;
    height: 34px;
    border-radius: 7px;
    border: 0.5px solid #e0e0e0;
    text-align: center;
    color: #7791a2;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    background-color: transparent;
    cursor: pointer;
  }

  .react-datepicker {
    box-shadow: 0 0 18px 4px rgba(81, 88, 93, 0.1);
    border: none;
    padding: 0 20px;

    .react-datepicker__triangle {
      border-bottom-color: white !important;

      &::before {
        display: none;
      }
    }
  }

  .react-datepicker__day--today {
    &:after {
      content: "";
      top: -5px;
      right: -12px;
      position: relative;
      border: 1px solid #7791a2;
      width: 3px;
      border-radius: 2px;
      display: block;
      height: 3px;
      background: #7791a2;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #7791a2;
    color: white !important;
  }

  .react-datepicker__day--selected.react-datepicker__day--today {
    &:after {
      content: none !important;
    }
  }

  .react-datepicker__navigation {
    width: 15px;
    height: 15px;
    border: none;
    margin-top: 12px;
  }

  .react-datepicker__navigation--next {
    background: url('../../../assets/images/right-shape.svg') no-repeat;
    margin-right: 27px;
  }

  .react-datepicker__navigation--previous {
    background: url('../../../assets/images/right-shape.svg') no-repeat;
    transform: scaleX(-1);
    margin-left: 27px;
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
    padding-top: 0;

    .react-datepicker__current-month {
      font-family: 'Source Sans Pro';
      font-size: 18px;
      font-weight: 600;
      color: #7791a2;
      padding: 15px 0;
    }

    .react-datepicker__day-name {
      color: #c6c6c6;
      font-size: 14px;
    }
  }

  .react-datepicker__month {
    margin-top: 0;

    .react-datepicker__week {
      .react-datepicker__day {
        color: #9b9b9b;
        font-size: 14px;
        font-family: 'Source Sans Pro';
        width: 28px;
        height: 28px;
        border-radius: 14px;
        border: 0.5px solid transparent;

        &:hover {
          background-color: transparent;
          border: 0.5px solid #7791a2;
        }

        &.react-datepicker__day--selected {
          color: white;
          background-color: #7791a2;

          &:hover {
            border: 0.5px solid transparent;
          }
        }
      }

      .react-datepicker__day--outside-month {
        color: transparent;
        pointer-events: none;
      }
    }
  }
}
