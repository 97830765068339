$primary-font-color: #7791a2;
$secondary-font-color: #9b9b9b;
$tertiary-font-color: #a8a8a8;
$button-hover: #ededed;
$secondary-button-hover: #f4f4f4;
$background-color: #f6f6f6;
$border-color: #e2e2e2;
$color-red: #e24f4f;
$color-blue-light: #7791a2;
$color-grey-light: #ececec;
$color-grey-blue-middle: #618eb0;
$color-grey-blue-dark: #1a415e;
$color-grey-shadow-dark: rgba(26, 65, 94, 0.47);
$color-grey-blue-light: #7791a2;
$color-grey-dark: #a4a4a4;
$color-grey-blue-light-hover: #93b6d0;
$color-black-font: #4b4b4b;
