@import "../../../assets/css/colors.scss";

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .magnify-container {
    width: 212px;
    height: 212px;
    background-color: white;
    border-radius: 106px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0;
  }

  .not-found-title {
    font-size: 24px;
    font-weight: 600;
    color: $primary-font-color;
  }

  .not-found-subtitle {
    font-size: 11px;
    color: #9b9b9b;
  }
}
