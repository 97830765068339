.pagination-container {
  display: flex;
  margin-top: 20px;

  .page-button {
    margin: 10px;
    color: #929292;
    font-size: 15px;
    cursor: pointer;

    &:hover {
      color: #618eb0;
    }

    &.current {
      color: #7791a2;
      font-weight: bold;
    }
  }

  .button-with-dots {
    display: flex;
    
    .dots {
      display: flex;
      align-items: center;
      .dot {
        width: 4px;
        height: 4px;
        margin: 0 2px;
        border-radius: 10px;
        background-color: #b5b5b5;
      }
    }
  }
}
