@import "../../assets/css/colors.scss";

.navbar {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 300px;

  & > div {
    margin-bottom: 18px;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal.fade.show {
  overflow-y: scroll;
    @media screen and (-webkit-min-device-pixel-ratio:0) {
    overflow-y: overlay;
  }
}

.modal-dialog {
  display: flex;
  justify-content: center;
  margin: 150px 0;
}

.modal-content {
  width: 100vh;
  background: white;
  display: flex;
  justify-content: center;
}

.mobile {
  padding: 0px;
}
