@import "../../../assets/css/colors.scss";

.text {
  padding-bottom: 15px;
  box-shadow: 0 1px 0 0 #e2e2e2;
  .label {
    line-height: 0.75;
    font-size: 17px;
    font-weight: 600;
    color: $color-black-font;
  } //end .label

  .text-container {
    min-height: 17px;
    max-height: 80px;
    padding: 15px;
    margin-top: 24px;
    border-radius: 6px;
    border: solid 1px $color-grey-blue-dark;
    font-size: 14px;
    font-style: italic;
    overflow-y: auto;
    color: $primary-font-color;
    overflow-wrap: break-word;
  } // text-container
} // end text

.mobile {
  .text {
    max-width: 300px;
  } // end text
} // .mobile
