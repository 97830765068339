@import "../../../assets/css/colors.scss";

.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-mobile-container {
  width: 300px;
  border: 1px solid #7791a2;
  border-radius: 4px;

  .title-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 70px;
    align-items: center;

    .table-title {
      font-size: 20px;
      font-weight: bold;
      color: #7791a2;
    }

    .table-button {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background-color: #7791a2;
      background-image: url('../../../assets/images/right-white-shape.svg');
      background-repeat: no-repeat;
      background-position: center;

      &.left-button {
        transform: scaleX(-1);
      }
    }
  }

  .mobile-cell {
    border-top: 0.5px solid #9b9b9b;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #9b9b9b;
    font-size: 18px;
    position: relative;
    .datepicker-container {
      margin: 0px auto;
      input {
        width: 120px;
      }
    }

    .value {
      position: absolute;
      left: 10px;
      width: calc(100% - 35px);
      text-align: center;
    }

    .arrow-container {
      height: 28px;
      width: 10px;
      position: absolute;
      right: 10px;
      text-align: center;
    }

    &:hover {
      background-color: $secondary-button-hover;
    }
  }
}
