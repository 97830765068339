@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

#root > div {
  height: 100%;
  position: absolute;
  width: 100%; }

.row:before, .row:after {
  display: none; }

#loadBar {
  height: 5px;
  width: 100%;
  background: white; }
  #loadBar #nprogress .bar {
    background: #618eb0 !important;
    height: 5px; }

::-webkit-scrollbar-track {
  background: none; }

.row-container {
  height: 45px;
  display: flex;
  align-items: center;
  width: 75%;
  border-bottom: solid 1px #e2e2e2;
  padding-right: 10px; }
  .row-container .cell {
    font-size: 16px;
    color: #4b4b4b;
    min-width: 100px;
    padding-right: 30px;
    font-weight: 600;
    flex: 1 1;
    text-align: left;
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 45px;
    line-height: 22.5px; }
    .row-container .cell.large {
      flex: 2 1; }
  .row-container.labels .cell {
    font-weight: bold;
    color: #7791a2; }
  .row-container .row-button-container {
    display: flex;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    align-items: center; }
    .row-container .row-button-container:hover {
      background-color: #7791a2;
      cursor: pointer; }
  .row-container .container-img-hover {
    background-image: url(/static/media/right-shape.553061cf.svg);
    height: 20px;
    width: 20px;
    background-position: center;
    background-repeat: no-repeat;
    padding: 6px 16px; }
    .row-container .container-img-hover:hover {
      background-image: url(/static/media/right-white-shape.46af8bda.svg); }
  .row-container .space {
    width: 28px; }
  .row-container.data:hover {
    background-color: white; }
    .row-container.data:hover .cell {
      color: #7791a2; }

.datepicker-container input {
  width: 90px;
  height: 34px;
  border-radius: 7px;
  border: 0.5px solid #e0e0e0;
  text-align: center;
  color: #7791a2;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  background-color: transparent;
  cursor: pointer; }

.datepicker-container .react-datepicker {
  box-shadow: 0 0 18px 4px rgba(81, 88, 93, 0.1);
  border: none;
  padding: 0 20px; }
  .datepicker-container .react-datepicker .react-datepicker__triangle {
    border-bottom-color: white !important; }
    .datepicker-container .react-datepicker .react-datepicker__triangle::before {
      display: none; }

.datepicker-container .react-datepicker__day--today:after {
  content: "";
  top: -5px;
  right: -12px;
  position: relative;
  border: 1px solid #7791a2;
  width: 3px;
  border-radius: 2px;
  display: block;
  height: 3px;
  background: #7791a2; }

.datepicker-container .react-datepicker__day--keyboard-selected {
  background-color: #7791a2;
  color: white !important; }

.datepicker-container .react-datepicker__day--selected.react-datepicker__day--today:after {
  content: none !important; }

.datepicker-container .react-datepicker__navigation {
  width: 15px;
  height: 15px;
  border: none;
  margin-top: 12px; }

.datepicker-container .react-datepicker__navigation--next {
  background: url(/static/media/right-shape.553061cf.svg) no-repeat;
  margin-right: 27px; }

.datepicker-container .react-datepicker__navigation--previous {
  background: url(/static/media/right-shape.553061cf.svg) no-repeat;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  margin-left: 27px; }

.datepicker-container .react-datepicker__header {
  background-color: white;
  border-bottom: none;
  padding-top: 0; }
  .datepicker-container .react-datepicker__header .react-datepicker__current-month {
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 600;
    color: #7791a2;
    padding: 15px 0; }
  .datepicker-container .react-datepicker__header .react-datepicker__day-name {
    color: #c6c6c6;
    font-size: 14px; }

.datepicker-container .react-datepicker__month {
  margin-top: 0; }
  .datepicker-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
    color: #9b9b9b;
    font-size: 14px;
    font-family: 'Source Sans Pro';
    width: 28px;
    height: 28px;
    border-radius: 14px;
    border: 0.5px solid transparent; }
    .datepicker-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
      background-color: transparent;
      border: 0.5px solid #7791a2; }
    .datepicker-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected {
      color: white;
      background-color: #7791a2; }
      .datepicker-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected:hover {
        border: 0.5px solid transparent; }
  .datepicker-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--outside-month {
    color: transparent;
    pointer-events: none; }

.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.table-mobile-container {
  width: 300px;
  border: 1px solid #7791a2;
  border-radius: 4px; }
  .table-mobile-container .title-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 70px;
    align-items: center; }
    .table-mobile-container .title-header .table-title {
      font-size: 20px;
      font-weight: bold;
      color: #7791a2; }
    .table-mobile-container .title-header .table-button {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background-color: #7791a2;
      background-image: url(/static/media/right-white-shape.46af8bda.svg);
      background-repeat: no-repeat;
      background-position: center; }
      .table-mobile-container .title-header .table-button.left-button {
        -webkit-transform: scaleX(-1);
                transform: scaleX(-1); }
  .table-mobile-container .mobile-cell {
    border-top: 0.5px solid #9b9b9b;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #9b9b9b;
    font-size: 18px;
    position: relative; }
    .table-mobile-container .mobile-cell .datepicker-container {
      margin: 0px auto; }
      .table-mobile-container .mobile-cell .datepicker-container input {
        width: 120px; }
    .table-mobile-container .mobile-cell .value {
      position: absolute;
      left: 10px;
      width: calc(100% - 35px);
      text-align: center; }
    .table-mobile-container .mobile-cell .arrow-container {
      height: 28px;
      width: 10px;
      position: absolute;
      right: 10px;
      text-align: center; }
    .table-mobile-container .mobile-cell:hover {
      background-color: #f4f4f4; }

.circle-container {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .circle-container:hover {
    background-color: white;
    cursor: pointer; }

.expanded-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 300px;
  border-radius: 25px;
  background-color: white; }
  .expanded-container .input-container {
    width: 60%; }
    .expanded-container .input-container input {
      width: 100%;
      height: 40px;
      font-size: 14px;
      border: none;
      line-height: 27px;
      color: #7791a2; }
      .expanded-container .input-container input:focus {
        outline: none; }
    .expanded-container .input-container ::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #7791a2;
      opacity: 1;
      /* Firefox */ }
    .expanded-container .input-container ::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #7791a2;
      opacity: 1;
      /* Firefox */ }
    .expanded-container .input-container ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #7791a2;
      opacity: 1;
      /* Firefox */ }
    .expanded-container .input-container :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #7791a2; }
    .expanded-container .input-container ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #7791a2; }

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .not-found-container .magnify-container {
    width: 212px;
    height: 212px;
    background-color: white;
    border-radius: 106px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0; }
  .not-found-container .not-found-title {
    font-size: 24px;
    font-weight: 600;
    color: #7791a2; }
  .not-found-container .not-found-subtitle {
    font-size: 11px;
    color: #9b9b9b; }

.sort-container {
  display: flex;
  align-items: center; }
  .sort-container .sort-text {
    color: #a8a8a8;
    font-size: 15px; }
  .sort-container .current-sort {
    color: #7791a2;
    font-size: 15px;
    margin-left: 5px;
    font-weight: bold;
    cursor: pointer; }
  .sort-container .triangle {
    display: flex;
    height: 12px;
    width: 12px; }
  .sort-container .css-v0m4dg:active {
    background-color: #B2D4FF; }
  .sort-container .css-10nd86i {
    cursor: pointer; }

.sort-container.mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 300px;
  border-radius: 25px;
  background-color: white; }
  .sort-container.mobile .sort-text {
    padding-left: 15px; }
  .sort-container.mobile select {
    border: none;
    background: none;
    color: #7791a2;
    font-size: 15px;
    margin-left: 5px;
    font-weight: bold;
    flex: 1 1; }

.pagination-container {
  display: flex;
  margin-top: 20px; }
  .pagination-container .page-button {
    margin: 10px;
    color: #929292;
    font-size: 15px;
    cursor: pointer; }
    .pagination-container .page-button:hover {
      color: #618eb0; }
    .pagination-container .page-button.current {
      color: #7791a2;
      font-weight: bold; }
  .pagination-container .button-with-dots {
    display: flex; }
    .pagination-container .button-with-dots .dots {
      display: flex;
      align-items: center; }
      .pagination-container .button-with-dots .dots .dot {
        width: 4px;
        height: 4px;
        margin: 0 2px;
        border-radius: 10px;
        background-color: #b5b5b5; }

.header-component {
  background-color: #fff;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 calc(10% + 15px); }
  .header-component.mobile {
    justify-content: center; }
    .header-component.mobile button {
      margin-left: 15px; }
  .header-component .vertical-separator {
    border-left: 1px solid blue;
    margin: 14px 18px; }
  .header-component .side-container {
    display: flex;
    height: 100%;
    align-items: center; }
  .header-component .logo {
    width: 150px;
    height: 30px; }
  .header-component button, .header-component a {
    border: none;
    box-shadow: none;
    color: #7791a2;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    padding: 10px 17px;
    border-radius: 6px;
    height: 44px; }
    .header-component button:focus, .header-component button:focus:active, .header-component a:focus, .header-component a:focus:active {
      background: transparent;
      box-shadow: none;
      outline: none; }
    .header-component button.sign-out, .header-component a.sign-out {
      font-weight: 600;
      padding: 0 10px; }
    .header-component button.sign-out:hover, .header-component a.sign-out:hover {
      background-color: #ededed;
      cursor: pointer; }

.header-and-footer {
  height: 100vh; }
  .header-and-footer .main-container {
    padding: 75px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100% - 270px); }
  .header-and-footer .credits-container {
    height: 70px;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    .header-and-footer .credits-container .copyright {
      font-size: 15px;
      color: #a7a7a7; }

@media all and (min-width: 720px) {
  .header-and-footer {
    min-width: 1050px; }
    .header-and-footer .main-container {
      min-width: 1050px;
      overflow-x: auto; } }

.navbar {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 300px; }
  .mobile-navbar > div {
    margin-bottom: 18px; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); }

.modal.fade.show {
  overflow-y: scroll; }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .modal.fade.show {
      overflow-y: overlay; } }

.modal-dialog {
  display: flex;
  justify-content: center;
  margin: 150px 0; }

.modal-content {
  width: 100vh;
  background: white;
  display: flex;
  justify-content: center; }

.mobile {
  padding: 0px; }

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .input-container .input {
    width: 100%;
    border: none;
    padding: 15px 0;
    height: auto;
    min-height: 18px;
    background: none;
    box-shadow: 0 1px 0 0 #e2e2e2;
    color: #7791a2;
    font-weight: 600;
    font-size: 16px; }
    .input-container .input:focus {
      outline: none; }
  .input-container .label {
    font-size: 17px;
    font-weight: 600;
    color: #4b4b4b; }

.mobile .input-container .input {
  width: 300px; }

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .container .label {
    font-size: 17px;
    font-weight: 600;
    color: #4b4b4b;
    margin-bottom: 28px; }
  .container .group-container {
    width: 100%;
    display: flex;
    justify-content: space-around; }
    .container .group-container .checkbox-container {
      display: flex;
      align-items: center; }
    .container .group-container .checkbox {
      width: 17px;
      height: 17px;
      border-radius: 2px;
      border: 0.7px solid #7791a2;
      display: flex;
      justify-content: center;
      align-items: center; }
      .container .group-container .checkbox .selected {
        width: 70%;
        height: 70%;
        background-color: #7791a2;
        border-radius: 1.3px; }
    .container .group-container .checkbox-label {
      margin-left: 5px;
      font-size: 15px;
      color: #4b4b4b; }

.mobile .container {
  width: 300px; }
  .mobile .container .group-container {
    width: 270px;
    padding-left: 15px;
    height: 80px;
    justify-content: space-between; }
    .mobile .container .group-container .checkbox-container {
      display: inline-flex; }

.button-container {
  width: 120px;
  height: 50px;
  border-radius: 6px;
  cursor: pointer;
  border: solid 1px #7791a2;
  background-color: #7791a2;
  display: flex;
  justify-content: center;
  align-items: center; }
  .button-container .button-text {
    font-size: 15px;
    color: white; }
  .button-container:hover {
    border: solid 1px #93b6d0;
    background-color: #93b6d0; }

.single-value-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 45px;
  padding-bottom: 15px;
  box-shadow: 0 1px 0 0 #e2e2e2;
  color: #9b9b9b; }
  .single-value-container .label {
    font-size: 17px;
    font-weight: 600;
    color: #4b4b4b; }
  .single-value-container .container-value {
    position: absolute;
    right: 15px;
    font-size: 14px; }
    .single-value-container .container-value .value {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      width: 50px;
      height: 40px;
      border: solid 1px #1a415e;
      color: #7791a2;
      font-size: 18px;
      font-weight: bold;
      margin-right: 10px; }

.mobile .single-value-container {
  width: 300px; }

.text {
  padding-bottom: 15px;
  box-shadow: 0 1px 0 0 #e2e2e2; }
  .text .label {
    line-height: 0.75;
    font-size: 17px;
    font-weight: 600;
    color: #4b4b4b; }
  .text .text-container {
    min-height: 17px;
    max-height: 80px;
    padding: 15px;
    margin-top: 24px;
    border-radius: 6px;
    border: solid 1px #1a415e;
    font-size: 14px;
    font-style: italic;
    overflow-y: auto;
    color: #7791a2;
    overflow-wrap: break-word; }

.mobile .text {
  max-width: 300px; }

.title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px; }
  .title-container .title-text {
    font-size: 36px;
    font-weight: 300;
    color: #7791a2; }
  .title-container .title-aux {
    width: 50px;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center, bottom;
    background-image: url(/static/media/back-arrow.772b40f3.svg); }
  .title-container .image {
    width: 50px;
    height: 36px;
    cursor: pointer; }

.content {
  display: flex; }
  .content .row {
    width: calc(50% - 40px); }
    .content .row.left {
      margin-right: 40px; }
    .content .row.right {
      margin-left: 40px; }
    .content .row > div {
      margin-bottom: 55px; }
    .content .row .single-value-container {
      margin-bottom: 27px; }

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0 40px; }
  .footer .back {
    font-size: 16px;
    color: #9b9b9b;
    margin-right: 10px; }
    .footer .back:hover {
      color: #7791a2; }
  .footer .button-container {
    margin-left: 10px; }

.mobile {
  width: 100%; }
  .mobile .content {
    flex-direction: column; }
  .mobile .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
  .mobile .row.right,
  .mobile .row.left {
    margin: 0; }
  .mobile .title-text {
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    color: #7791a2; }
  .mobile .footer {
    margin: 40px 0; }

@media screen and (min-width: 720px) {
  .title-container .title-aux {
    background-size: cover;
    background-image: url(/static/media/shape.3374e7e5.svg); }
    .title-container .title-aux:hover {
      background-image: url(/static/media/shape_hover.bba21171.svg); }
  .form-content-container {
    width: 75%; } }

.login-page {
  background-color: white;
  height: 100%;
  width: 100%; }

.login-container {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.logo-container {
  width: 179px;
  height: 57px;
  object-fit: contain;
  margin: auto; }
  .logo-container.desktop {
    width: 232px; }
  .logo-container .logo {
    width: inherit; }

.login-user {
  background-color: white;
  border-radius: 5px;
  margin: 40px auto;
  padding: 10px 75px;
  width: 250px; }
  .login-user.desktop {
    box-shadow: 0 10px 24px 0 rgba(26, 65, 94, 0.47); }
  .login-user form {
    margin: 50px 0; }
  .login-user .form-group {
    margin-bottom: 40px;
    position: relative; }
    .login-user .form-group .input-group {
      display: flex;
      flex-wrap: nowrap; }
    .login-user .form-group.inactive {
      border-bottom: solid 1px #ececec; }
      .login-user .form-group.inactive .user-icon {
        background-image: url(/static/media/user-inactive.d7fdf977.svg); }
      .login-user .form-group.inactive .password-icon {
        background-image: url(/static/media/pass-inactive.39481bac.svg); }
    .login-user .form-group.active {
      border-bottom: solid 1px #618eb0; }
      .login-user .form-group.active .user-icon {
        background-image: url(/static/media/user-active.f2d7482f.svg); }
      .login-user .form-group.active .password-icon {
        background-image: url(/static/media/pass-active.9b9a613a.svg); }
    .login-user .form-group .input-error-message {
      display: none; }
    .login-user .form-group.error {
      border-bottom-color: #e24f4f; }
      .login-user .form-group.error .input-error-message {
        bottom: -22px;
        color: #e24f4f;
        display: block;
        font-size: 14px;
        font-weight: 600;
        position: absolute; }
    .login-user .form-group input {
      border: none;
      box-shadow: none;
      color: #1a415e;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: normal;
      padding-left: 20px; }
      .login-user .form-group input::-webkit-input-placeholder {
        color: #7791a2; }
      .login-user .form-group input::-ms-input-placeholder {
        color: #7791a2; }
      .login-user .form-group input::placeholder {
        color: #7791a2; }
      .login-user .form-group input:focus {
        box-shadow: none;
        outline: none; }
    .login-user .form-group .input-icon {
      background-color: transparent;
      border: none;
      padding: 6px 0px;
      width: 25px; }
      .login-user .form-group .input-icon div {
        background-repeat: no-repeat;
        height: 17px;
        margin: 8px auto;
        width: 17px; }
  .login-user button.btn {
    background-color: #618eb0;
    border: none;
    border-radius: 9px;
    color: white;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding: 13px 16px;
    width: 100%;
    cursor: pointer; }
    .login-user button.btn:disabled {
      background-color: #ececec;
      color: #a4a4a4;
      cursor: not-allowed; }
    .login-user button.btn:focus {
      box-shadow: none;
      background-color: #618eb0;
      color: white;
      outline: none; }
  .login-user .error-message {
    color: #e24f4f;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin: 15px 0;
    text-align: center; }

.mobile .login-user {
  padding: 0; }

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

