@import "../../assets/css/colors.scss";

.title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;

  .title-text {
    font-size: 36px;
    font-weight: 300;
    color: #7791a2;
  }

  .title-aux {
    width: 50px;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center, bottom;
    background-image: url('../../assets/images/back-arrow.svg');
  }

  .image {
    width: 50px;
    height: 36px;
    cursor: pointer;
  }
}

.content {
  display: flex;

  .row {
    width: calc(50% - 40px);
    &.left {
      margin-right: 40px;
    }

    &.right {
      margin-left: 40px;
    }

    & > div {
      margin-bottom: 55px;
    }

    .single-value-container {
      margin-bottom: 27px;
    }
  }

}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0 40px;

  .back {
    font-size: 16px;
    color: #9b9b9b;
    margin-right: 10px;

    &:hover {
      color: #7791a2;
    }
  }

  .button-container {
    margin-left: 10px;
  }
}

.mobile {
  width: 100%;
  .content {
    flex-direction: column;
  }
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .row.right,
  .row.left {
    margin: 0
  }

  .title-text {
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    color: #7791a2;
  }

  .footer {
    margin: 40px 0;
  }
}


@media screen and (min-width: 720px) {
  .title-container {
    .title-aux {
      background-size: cover;
      background-image: url('../../assets/images/shape.svg');
      &:hover {
        background-image: url('../../assets/images/shape_hover.svg');
      }
    }
  } // end title-container
  .form-content-container {
    width: 75%;
  }
}
