@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import "./assets/css/colors.scss";

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root > div {
  height: 100%;
  position: absolute;
  width: 100%;
}

.row:before, .row:after{
   display: none;
 }

#loadBar {
  height: 5px;
  width: 100%;
  background: white;

  #nprogress .bar {
    background: $color-grey-blue-middle !important;
    height: 5px
  }
}

::-webkit-scrollbar-track {
    background: none;
}
