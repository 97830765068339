@import "../../../assets/css/colors.scss";

.single-value-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 45px;
  padding-bottom: 15px;
  box-shadow: 0 1px 0 0 #e2e2e2;
  color: $secondary-font-color;
  .label {
    font-size: 17px;
    font-weight: 600;
    color: $color-black-font;
  }

  .container-value {
    position: absolute;
    right: 15px;
    font-size: 14px;
    .value {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      width: 50px;
      height: 40px;
      border: solid 1px $color-grey-blue-dark;
      color: $primary-font-color;
      font-size: 18px;
      font-weight: bold;
      margin-right: 10px;
    } // end value
  } // end container-value
} //single-value-container

.mobile {
  .single-value-container {
    width: 300px;
  }
}
