@import "../../assets/css/colors.scss";

.header-and-footer {
  height: 100vh;
  .main-container {
    padding: 75px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100% - 270px);
  }

  .credits-container {
    height: 70px;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .copyright {
      font-size: 15px;
      color: #a7a7a7;
    }
  }
}

@media all and (min-width: 720px) {
  .header-and-footer {
    min-width: 1050px;
    .main-container {
      min-width: 1050px;
      overflow-x: auto;
    }
  }
}
