@import "../../../assets/css/colors.scss";

.button-container {
  width: 120px;
  height: 50px;
  border-radius: 6px;
  cursor: pointer;
  border: solid 1px #7791a2;
  background-color: #7791a2;
  display: flex;
  justify-content: center;
  align-items: center;

  .button-text {
    font-size: 15px;
    color: white;
  }

  &:hover {
    border: solid 1px $color-grey-blue-light-hover;
    background-color: $color-grey-blue-light-hover;
  }
}
