@import "../../../assets/css/colors.scss";

.sort-container {
  display: flex;
  align-items: center;

  .sort-text {
    color: $tertiary-font-color;
    font-size: 15px;
  }
  .current-sort {
    color: $primary-font-color;
    font-size: 15px;
    margin-left: 5px;
    font-weight: bold;
    cursor: pointer;
  }
  .triangle {
    display: flex;
    height: 12px;
    width: 12px;
  }

  //Active pseudostate of active option in select box
  .css-v0m4dg:active {
    background-color: #B2D4FF;
  }
  
  .css-10nd86i {
      cursor: pointer;
  }

}

.sort-container.mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 300px;
  border-radius: 25px;
  background-color: white;

  .sort-text {
    padding-left: 15px;
  }

  select {
    border: none;
    background: none;
    color: $primary-font-color;
    font-size: 15px;
    margin-left: 5px;
    font-weight: bold;
    flex: 1;
  }
}
