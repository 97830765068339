@import "../../../assets/css/colors.scss";

.circle-container {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: white;
    cursor: pointer;
  }
}

.expanded-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 300px;
  border-radius: 25px;
  background-color: white;

  .input-container {
    width: 60%;

    input {
      width: 100%;
      height: 40px;
      font-size: 14px;
      border: none;
      line-height: 27px;
      color: $primary-font-color;

      &:focus {
        outline: none;
      }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $primary-font-color;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $primary-font-color;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $primary-font-color;
    }
  }
}
