@import "../../../assets/css/colors.scss";

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .input {
    width: 100%;
    border: none;
    padding: 15px 0;
    height: auto;
    min-height: 18px;
    background: none;
    box-shadow: 0 1px 0 0 $border-color;
    color: $color-grey-blue-light;
    font-weight: 600;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  .label {
    font-size: 17px;
    font-weight: 600;
    color: $color-black-font;
  }
}

.mobile {
  .input-container {
    .input {
      width: 300px;
    }
  }
}
