@import "../../../assets/css/colors.scss";

.row-container {
  height: 45px;
  display: flex;
  align-items: center;
  width: 75%;
  border-bottom: solid 1px $border-color;
  padding-right: 10px;

  .cell {
    font-size: 16px;
    color: $color-black-font;
    min-width: 100px;
    padding-right: 30px;
    font-weight: 600;
    flex: 1;
    text-align: left;
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 45px;
    line-height: 22.5px;


    &.large {
      flex: 2;
    }
  }

  &.labels {
    .cell {
      font-weight: bold;
      color: $primary-font-color;
    }
  }

  .row-button-container {
    display: flex;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    align-items: center;

    &:hover {
      background-color: $color-blue-light;
      cursor: pointer;

    }

  }

  .container-img-hover{
    background-image: url("../../../assets/images/right-shape.svg");
    height: 20px;
    width: 20px;
    background-position: center;
    background-repeat: no-repeat;
    padding: 6px 16px;

    &:hover{
      background-image: url("../../../assets/images/right-white-shape.svg");
    }
  }

  .space {
    width: 28px;
  }

  &.data:hover{
    background-color: white;

    .cell {
      color: $primary-font-color;
    }
  }
}
