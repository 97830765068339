@import "../../../assets/css/colors.scss";

.header-component {
  background-color: #fff;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 calc(10% + 15px);

  &.mobile {
    justify-content: center;

    button {
      margin-left: 15px;
    }
  }

  .vertical-separator {
    border-left: 1px solid blue;
    margin: 14px 18px;
  }

  .side-container {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .logo {
    width: 150px;
    height: 30px;
  }

  button, a {
    border: none;
    box-shadow: none;
    color: $primary-font-color;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    padding: 10px 17px;
    border-radius: 6px;
    height: 44px;

    &:focus, &:focus:active {
      background: transparent;
      box-shadow: none;
      outline: none;
    }

    &.sign-out {
      font-weight: 600;
      padding: 0 10px
    }

    &.sign-out:hover {
      background-color: $button-hover;
      cursor: pointer;
    }
  }
}
